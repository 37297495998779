import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { slugify } from '../utils/utils'
import Experiment from '../xnat/Experiment';
import Project from '../xnat/Project';
import Subject from '../xnat/Subject';
import { host } from '../config';

const STATES = {
  READY: 0,
  DESTINED: 1,
  UPLOADING: 2,
  DONE: 6,
  FAILED: 7,
};

export default class Upload extends Component {

  static propTypes = {
    bundle: PropTypes.object.isRequired,
    onFinish: PropTypes.func,
    onUpdateStatus: PropTypes.func,
    project: PropTypes.instanceOf(Project),
    subject: PropTypes.instanceOf(Subject),
    experiment: PropTypes.instanceOf(Experiment),
  }

  static defaultProps = {
    onFinish: {},
    onUpdateStatus: {},
  }

  state = {
    error: null,
    progress: 0,
    uploadStatus: STATES.DESTINED,
    projectID: null,
    subjectID: null,
    experimentID: null,
  }

  componentDidMount() {
    let { project, subject, experiment } = this.props;
    let projectID, subjectID, experimentID, scanID;

    projectID = project.data.project;
    if (!subject) {
      subjectID = this.props.bundle.edf.header.patientIdentification.split(' ')[0];
    } else {
      subjectID = subject.data.subject;
    }
    if (!experiment) {
      experimentID = this.props.bundle.edf.header.recordIdentification.split(' ')[2];
    } else {
      experimentID = experiment.data.experiment;
    }
    scanID = slugify(this.props.bundle.edf.file.name.replace('.edf', ''), '_')

    this.setState({ projectID, subjectID, experimentID, scanID });
  }

  updateStatus = (uploadStatus) => {
    this.props.bundle.uploadStatus = uploadStatus;
    this.props.onUpdateStatus(this.props.bundle, uploadStatus);
    this.setState({ uploadStatus });
  }

  finish = (error) => {
    this.setState({
      error,
      progress: 0,
    });
    this.updateStatus(error ? STATES.FAILED : STATES.READY)
  }

  getOrCreateExperiment = () => {
    if (!this.props.experiment) {
      return new Experiment({
        host,
        project: this.state.projectID,
        subject: this.state.subjectID,
        experiment: this.state.experimentID,
        type: 'biosignals:edfSessionData',
      }).create();
    }
    return this.props.experiment;
  }

  startUpload = async () => {
    let error = null;

    try {
      this.updateStatus(STATES.UPLOADING);

      let experiment = await this.getOrCreateExperiment();
      let scan = await experiment.createScan({
        scan: this.state.scanID,
        type: 'biosignals:edfScanData'
      });
      let resource = await scan.createResource({ resource: 'EDF' });

      await resource.createFile(this.props.bundle.edf.file.file, this.state.scanID + '.edf', progress => this.setState({ progress }));
      if (this.props.bundle.artifacts) {
        await resource.createFile(this.props.bundle.artifacts.file.file, this.state.scanID, progress => this.setState({ progress }));
      }

      this.updateStatus(STATES.DONE);
    }
    catch (e) {
      console.error(e);
      error = e.status;
      this.finish(error);
    }
  }

  render() {
    const { uploadStatus, error } = this.state;
    const { projectID, subjectID, experimentID, scanID } = this.state;
    const dest = `${projectID}/${subjectID}/${experimentID}`
    const progress = `${this.state.progress.toFixed(2)}%`;
    const Alert = ({ type = 'info', children }) => <div className={`alert alert-${type}`}>{children}</div>;

    switch (uploadStatus) {
      case STATES.UPLOADING:
        return (
          <div className="list-group m-b-1">
            <Alert>
              <span>Uploading</span>
              <div className="progress-bar progress-info">
                <div style={{ width: progress }}>{progress}</div>
              </div>
            </Alert>
          </div>
        );

      case STATES.DONE:
        return (
          <div className="list-group m-b-1">
            <Alert type="info"><strong>Uploaded</strong> {scanID}.</Alert>
            <p>Uploaded <code>{scanID}</code> to <code>{dest}</code>.</p>
            <button onClick={() => this.finish(false)}>Done</button>
          </div>
        );

      case STATES.FAILED:
        return (
          <div className="list-group m-b-1">
            <Alert type="error"><strong>Error</strong> {error}.</Alert>
            <p>Failed to upload <code>{scanID}</code> to <code>{dest}</code>.</p>
            <button onClick={this.startUpload}>Retry Upload</button>
          </div>
        );

      default: // STATES.DESTINED and no state (which should not happen)
        return (
          <div className="list-group m-b-1">
            <Alert type="info"><strong>Upload</strong></Alert>
            <p>Upload <code>{scanID}</code> to <code>{dest}</code>.</p>
            <button onClick={this.startUpload}>Start Upload</button>
          </div>
        );

    }
  }

}
