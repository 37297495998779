import 'tawian-frontend';
import 'typeface-cousine';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
// import registerServiceWorker from 'utils/registerServiceWorker';
import './index.css';
import './dygraph.css';

ReactDOM.render(<App />, document.getElementById('app'));
// registerServiceWorker();
