import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dygraph from '../dygraphs/dygraph';
import {saveAs} from 'file-saver';
import goldStandardTxt from '../samples/SN001_sleepscoring_mod.txt';

export default class Hypnogram extends Component {

    static propTypes = {
        start: PropTypes.instanceOf(Date).isRequired,
        end: PropTypes.instanceOf(Date).isRequired,
        dateWindow: PropTypes.array.isRequired,
    }

    constructor(props) {
        super(props);
        const {start, end} = this.props;
        const epochs = Math.floor((end - start) / 1000 / 30);
        const data = _.range(epochs).map((ar, i) => [new Date(+start + i * 30 * 1000), null]);
        const stages = [[start, 5], ...data, [end, 1]];

        this.state = {stages};
    }

    componentDidMount() {
        this.setupGraph();
        this.attachHandlers();
        this.appendAxis();
    }

    componentDidUpdate(prevProps) {
        if (this.props.dateWindow !== prevProps.dateWindow) {
            this.range.updateOptions({dateWindow: this.props.dateWindow});
            // update current stage
        }
    }

    componentWillUnmount() {
        this.detachHandlers();
    }

    setupGraph = () => {
        const rangeHeight = this.container.offsetHeight;
        this.range = new Dygraph(
            this.container,
            this.state.stages,
            {
                dateWindow: this.props.dateWindow,
                xAxisHeight: rangeHeight,
                valueRange: [1, 5],
                axes: {
                    x: {drawAxis: false},
                },
                showLabelsOnHighlight: false,
                stepPlot: true,
                includeZero: true,
                connectSeparatedPoints: false,
                showRangeSelector: true,
                rangeSelectorHeight: rangeHeight,
                rangeSelectorAlpha: 0,
                rangeSelectorPlotFillGradientColor: 'white',
                rangeSelectorForegroundStrokeColor: 'grey',
                rangeSelectorBackgroundStrokeColor: 'white',
                rangeSelectorPlotFillColor: 'white',
                rangeSelectorPlotStrokeColor: 'black',
                rangeSelectorForegroundLineWidth: 0,
            },
        );
        this.range.name = 'Hypnogram'; // for debugging
        this.range.draw = this.range.drawGraph_.bind(this.range);
    }

    attachHandlers = () => {
        window.addEventListener('keydown', this.handleKeydown);
    }

    detachHandlers = () => {
        window.removeEventListener('keydown', this.handleKeydown);
    }

    handleKeydown = (e) => {
        const keyMap = {
            8: () => this.setStageForEpoch(null), // delete
            27: () => this.setStageForEpoch(null), // escape
            49: () => this.setStageForEpoch(1), //1
            50: () => this.setStageForEpoch(2), //2
            51: () => this.setStageForEpoch(3), //3
            53: () => this.setStageForEpoch(4), //5
            48: () => this.setStageForEpoch(0), //0
            13: () => this.computeScoringResults(), // Enter
        };
        (keyMap[e.which || e.keyCode] || _.noop)();
    }

    appendAxis = () => {
        const xAxis = document.createElement('div');
        xAxis.className = 'dygraph-axis-label';
        xAxis.innerHTML = 'W<br>N1<br>N2<br>N3<br>R';
        this.container.firstChild.appendChild(xAxis);
    }

    setStageForEpoch = async (newStage) => {
        const {stages} = this.state;
        const {start, dateWindow} = this.props;
        const epoch = Math.floor((dateWindow[1] - start) / 1000 / 30);
        // const currentStage = stages[epoch][1];
        // const newStage = Math.min(Math.max(currentStage + stageDiff, 0), 5) || null;
        stages[epoch][1] = newStage;
        this.range.updateOptions({file: stages});

    }

    computeScoringResults = async () => {
        const {stages} = this.state;
        // load file
        const goldStandardString = await fetch(goldStandardTxt)
            .then(r => r.text())
            .then(text => {
                return text;
            });
        //convert gold standard to array
        let goldStandardArrayRaw = goldStandardString.split("\n")


        // format arrays

        let goldStandardArray = [];
        for (let i=1; i<goldStandardArrayRaw.length; i++){
            let currentLine = goldStandardArrayRaw[i];
            let cut = currentLine.substring(currentLine.indexOf("Sleep stage ")+12);
            cut = cut.substring(0,cut.length-1);
            switch (cut){
                case 'W' :
                    cut = 0;
                    break;
                case 'N1' :
                    cut = 1;
                    break;
                case 'N2' :
                    cut = 2;
                    break;
                case 'N3' :
                    cut = 3;
                    break;
                case 'R' :
                    cut = 4;
                    break;
            }
            goldStandardArray.push( [i, cut] );
        }
        // never to be heard of again!
        goldStandardArray[853][1] = 0;

        // convert stages array to working array
        let userScoreObjectArray = stages;
        //console.log(userScoreObjectArray);
        userScoreObjectArray = stages.slice(1,-2);
        //userScoreObjectArray.splice(0,1);
        let userScoreArray = [];
        for (let i=0; userScoreObjectArray.length>i; i++){
            userScoreArray.push([i+1, userScoreObjectArray[i][1]]);
        }
        //console.log(userScoreArray);
        // split GS-Array into five by sleep stage
        let gs0 = [];
        let gs1 = [];
        let gs2 = [];
        let gs3 = [];
        let gs4 = [];

        for (let i=0; goldStandardArray.length>i; i++) {
            const stage = goldStandardArray[i][1];
            switch(stage){
                case 0 :
                    gs0.push(goldStandardArray[i]);
                    //console.log("füge eine 0 hinzu an stelle; ", i);
                    break;
                case 1 :
                    gs1.push(goldStandardArray[i]);
                    break;
                case 2 :
                    gs2.push(goldStandardArray[i]);
                    break;
                case 3 :
                    gs3.push(goldStandardArray[i]);
                    break;
                case 4 :
                    gs4.push(goldStandardArray[i]);
                    break;
                default:
                    console.log("Nix!", i, stage);
                    break;
            }
        }

        // catched them all?
        //console.log("sum before: ", goldStandardArray.length);
        //console.log("sum: ", gs0.length + gs1.length + gs2.length + gs3.length + gs4.length);



        function computeResults(gs){
            // for all Ws(0) in this list compare with user input
            let results = [0,0,0,0,0,0]
            for (let i=0; gs.length>i; i++){
                let currentEpoch = gs[i][0]-1;
                let choice = userScoreArray[currentEpoch][1];
                switch(choice){
                    case 0:
                        results[0] = results[0]+1;
                        break;
                    case 1:
                        results[1] = results[1]+1;
                        break;
                    case 2:
                        results[2] = results[2]+1;
                        break;
                    case 3:
                        results[3] = results[3]+1;
                        break;
                    case 4:
                        results[4] = results[4]+1;
                        break;
                    case null:
                        results[5] = results[5]+1;
                        break;
                    default:
                        console.log("darf nicht sein oder ist null!", userScoreArray[currentEpoch]);
                }
            }

            // compute percentage
            let col = [null, null, null, null, null, null];
            col[0] = results[0]/gs.length*100;
            col[1] = results[1]/gs.length*100;
            col[2] = results[2]/gs.length*100;
            col[3] = results[3]/gs.length*100;
            col[4] = results[4]/gs.length*100;
            col[5] = results[5]/gs.length*100;
            //console.log(col);
            return col;
        }

        const col1 = computeResults(gs0).map(function(each_element){
            return Number(each_element.toFixed(2));
        });
        const col2 = computeResults(gs1).map(function(each_element){
            return Number(each_element.toFixed(2));
        });
        const col3 = computeResults(gs2).map(function(each_element){
            return Number(each_element.toFixed(2));
        });
        const col4 = computeResults(gs3).map(function(each_element){
            return Number(each_element.toFixed(2));
        });
        const col5 = computeResults(gs4).map(function(each_element){
            return Number(each_element.toFixed(2));
        });

        const result = [col1,col2,col3,col4,col5];

        console.log("col 1:", col1);
        console.log("col 2:", col2);
        console.log("col 3:", col3);
        console.log("col 4:", col4);
        console.log("col 5:", col5);

        let csv = "";
        csv += ";;Gold;standard\n"
        csv += ";%;W;N1;N2;N3;REM\n";
        csv += ";W;"+col1[0]+";"+col2[0]+";"+col3[0]+";"+col4[0]+";"+col5[0]+";\n";
        csv += ";N1;"+col1[1]+";"+col2[1]+";"+col3[1]+";"+col4[1]+";"+col5[1]+";\n";
        csv += "User annotation;N2;"+col1[2]+";"+col2[2]+";"+col3[2]+";"+col4[2]+";"+col5[2]+";\n";
        csv += ";N3;"+col1[3]+";"+col2[3]+";"+col3[3]+";"+col4[3]+";"+col5[3]+";\n";
        csv += ";REM;"+col1[4]+";"+col2[4]+";"+col3[4]+";"+col4[4]+";"+col5[4]+";\n";
        csv += ";Unscored;"+col1[5]+";"+col2[5]+";"+col3[5]+";"+col4[5]+";"+col5[5]+";\n";


        //var resultExport = JSON.stringify(result);
        var blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
        //console.log("export array: ", stages);
        saveAs(blob, "scoring-results.csv");


    }

    render() {
        const ref = el => this.container = el;
        const style = {height: 100, width: '100%'};
        return <div ref={ref} style={style} className="hypnogram"/>;
    }

}
